import React from 'react'
import Img from 'gatsby-image'

const Image = ({ image, alt, ...props }) => {
    if (!image) return null

    if (image.hasOwnProperty('url') || typeof image === 'string') {
        const url = typeof image === 'string' ? image : image.url
        return <img alt={alt || ''}  {...props} src={url}/>
    } else if (typeof image === 'object' && image.hasOwnProperty('childImageSharp')) {
        if (image.childImageSharp.hasOwnProperty('fluid')) {
            return <Img {...props} fluid={image.childImageSharp.fluid}/>
        } else if (image.childImageSharp.hasOwnProperty('fixed')) {
            return <Img {...props} fixed={image.childImageSharp.fixed}/>
        }
    } else if (typeof image === 'string') {
    }
}

export default Image