import React from 'react'

import Carousel from './Carousel'
import VideoFrame from '../components/VideoFrame'

class Videos extends React.Component {
    constructor(data) {
        super(data);
        this.videos = data.videos || [];
        this.className = `${data.className} videos__section`
    }

    render() {
        if (this.videos && this.videos.length > 0) {
            return (
                <section id="videos" className={this.className}>
                    <Carousel>
                        {this.videos.map((video, i) => {
                            if (video && video.videoURL && video.title)
                                return <VideoFrame key={i} video={video}/>
                            return null
                        })}
                    </Carousel>
                </section>
            )
        } else {
            return <div></div>
        }
    }
}

export default Videos