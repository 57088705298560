import React from 'react'

import VideoEmbed from './VideoEmbed'

class VideoFrame extends React.Component {
    constructor(data) {
        super(data);
        this.video = data.video;
        this.className = data.className
    }

    render() {
        return (
        <div className={`video${this.className ? ' ' + this.className : ''}`}>
            <div className="video__panel">
                <VideoEmbed onMouseOver={this.onClick} videoInfo={this.video}/>
            </div>
            <div className="video__info">
                <span className="video__title">{this.video.title}</span>
                {/* <span className="video__description" 
                    dangerouslySetInnerHTML={{ __html: this.video.description }}></span> */}
            </div>
        </div>
    )}
}

export default VideoFrame