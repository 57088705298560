import React from 'react'

const VideoEmbed = ({ videoInfo }) => {
    function embedFromURL(url) {
        let split;
        if (url.includes('=')) {
            split = url.split('=')
        } else {
            split = url.split('/')
        }

        let id = split[split.length-1]
        return `https://www.youtube.com/embed/${id}`
    }

    let { videoURL, title } = videoInfo
    if (!videoURL.includes('embed')) {
        videoURL = embedFromURL(videoURL)
    }

    return (
        <div className="video__container">
            <iframe 
                src={videoURL}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
        </div>
    )
}

export default VideoEmbed