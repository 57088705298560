//@ts-check
import React, { useRef, useState, useCallback } from 'react'
import axios from "axios"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ContactForm = ({ formUrl, contactReceivedMessage }) => {
	const { executeRecaptcha } = useGoogleReCaptcha()
	const [query, setQuery] = useState({
		name: '',
		email: '',
		more: '',
		where: '',
		when: '',
		message: '',
		'g-recaptcha-response': ''
	})

	const formRef = useRef(null)

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	})

	const handleServerResponse = (ok, msg) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		})

		if (ok) {
			setQuery({
				name: '',
				email: '',
				more: '',
				where: '',
				when: '',
				message: '',
				'g-recaptcha-response': ''
			});
		}
	}

	const handleOnSubmit = useCallback(async e => {
		e.preventDefault()

		if (serverState.submitting) return

		setServerState({ submitting: true })

		const result = await executeRecaptcha('contact_form');
		setQuery({ ...query, 'g-recaptcha-response': result });
		console.log({ result, query });

		//if (query['g-recaptcha-response'] === '') {
		//	return handleServerResponse(false, 'Please complete ReCaptcha.')
		//}


		axios({
			method: "post",
			url: formUrl,
			data: {
				...query,
				'g-recaptcha-response': result
			}
		})
			.then(r => {
				handleServerResponse(true, contactReceivedMessage)
			})
			.catch(r => {
				console.log({ r })
				handleServerResponse(false, r.response.data.error)
			})
	}, [executeRecaptcha, query, handleServerResponse])

	return (
		<form className="contact-form"
			name="contact"
			method="POST"
			action={formUrl}
			ref={formRef}
			onSubmit={handleOnSubmit}>
			<section className="contact-form__section">
				<label htmlFor="name" className="contact-form__label">Your name</label>
				<input
					type="text"
					className="contact-form__input contact-form__input--text"
					placeholder="Your name"
					name="name"
					id="name"
					aria-describedby="error-name"
					aria-invalid={false}
					required={true}
					value={query.name}
					onChange={e => setQuery({ ...query, name: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-name"
					hidden={true} />
			</section>
			<section className="contact-form__section">
				<label htmlFor="email" className="contact-form__label">Email address</label>
				<input
					type="email"
					className="contact-form__input contact-form__input--text"
					placeholder="Email address"
					name="email"
					id="email"
					aria-describedby="error-email"
					aria-invalid={false}
					required={true}
					value={query.email}
					onChange={e => setQuery({ ...query, email: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-email"
					hidden={true} />
			</section>
			<section className="contact-form__section">
				<label htmlFor="more" className="contact-form__label">Other contact info</label>
				<input
					type="text"
					className="contact-form__input contact-form__input--text"
					placeholder="Phone number"
					name="more"
					id="more"
					aria-describedby="error-more"
					aria-invalid={false}
					value={query.more}
					onChange={e => setQuery({ ...query, more: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-more"
					hidden={true}></div>
			</section>
			<section className="contact-form__section">
				<label htmlFor="where" className="contact-form__label">Location</label>
				<input
					type="text"
					className="contact-form__input contact-form__input--text"
					placeholder="The performance location."
					name="where"
					id="where"
					aria-describedby="error-where"
					aria-invalid={false}
					required={true}
					value={query.where}
					onChange={e => setQuery({ ...query, where: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-where"
					hidden={true}></div>
			</section>
			<section className="contact-form__section">
				<label htmlFor="when" className="contact-form__label">When do you need to book for?</label>
				<input
					type="text"
					className="contact-form__input contact-form__input--text"
					placeholder="When do you need to book for?"
					name="when"
					id="when"
					aria-describedby="error-when"
					aria-invalid={false}
					required={true}
					value={query.when}
					onChange={e => setQuery({ ...query, when: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-when"
					hidden={true}></div>
			</section>
			<section className="contact-form__section contact-form__section--message">
				<label htmlFor="message" className="contact-form__label">Message</label>
				<textarea
					className="contact-form__input contact-form__input--textarea"
					placeholder="Message"
					name="message"
					id="message"
					aria-describedby="error-message"
					aria-invalid={false}
					required={true}
					value={query.message}
					onChange={e => setQuery({ ...query, message: e.target.value })} />
				<div className="contact-form__error"
					role="alert"
					id="error-message"
					hidden={true}></div>
				<input
					type="hidden"
					id="captchaResponse"
					name="g-recaptcha-response"
					value={query['g-recaptcha-response']} />
			</section>
			<section className="contact-form__section contact-form__section--submit">
				<button className={`arrow-button ${serverState.submitting ? 'arrow-button--submitting' : ''}`} type="submit" disabled={serverState.submitting}>
					<span className="arrow-button__inner">Send message
						<svg viewBox="0 0 481.5 293.5"><defs></defs><path fill="currentColor" d="M468.6 59.6l-46.7-46.7-187.2 187.2L46.7 12 0 58.7l188.1 188 46.6 46.7.1.1 46.7-46.7-.1-.1z"></path></svg>
					</span>
				</button>
				{serverState.status && (
					<p className={!serverState.status.ok ? "contact__form-error" : ""}>
						{serverState.status.msg}
					</p>
				)}
			</section>
		</form>
	)
}

export default ContactForm
