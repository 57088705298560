import React from 'react'

import ContactForm from './ContactForm'

const Contact = ({
    phoneNumber,
	email,
	contactReceivedMessage,
	formUrl,
}) => {
    if (!phoneNumber) phoneNumber = '+610000000000'
    if (!email) email = 'john@doe.com.au'
    let phoneNumberHref = phoneNumber.match(/([0-9,+])/g).join('')

    return (
        <div id="contact" className="container contact alt-section" style={{width: '100%'}}>
            <div className="contact__content">
                <div className="split split--70-30 split--large">
                    <div className="contact__column">
                        <ContactForm contactReceivedMessage={contactReceivedMessage} formUrl={formUrl}/>
                    </div>
                    <div className="contact__column">
						<div className="connect">
							<ol className="connect__list">
								<li className="connect__item">
									<a href={`tel:${phoneNumberHref}`} className="connect__link">{phoneNumber}</a>
								</li>
								<li className="connect__item">
									<a href={`mailto:${email}`} className="connect__link">{email}</a>
								</li>
							</ol>
						</div>
					</div>
                </div>
            </div>
        </div>
    )
}

export default Contact