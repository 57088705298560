import React from 'react'
import Carousel, { consts } from 'react-elastic-carousel'

import Icon from './Icon'

const customArrow = ({ type, onClick, isEdge }) => {
    const direction = type === consts.PREV ? 'chevron_left' : 'chevron_right'
    return <button 
        className={`carousel__side carousel__side--${direction}`}
        onClick={onClick}
        disabled={isEdge}>
        <Icon name={direction}/>
    </button>
}

const numbersPagination = ({ pages, activePage }) => {
    return <div className="carousel__pagination carousel__pagination--numbers">
        <span>{activePage+1}/{pages.length}</span>
    </div>
}

const dotsPagination = ({ pages, activePage, onClick }) => {
    return <div className="carousel__pagination carousel__pagination--dots">
        { pages && pages.length > 0 &&
            pages.map(page => {
                const isActivePage = activePage === page
                return <button 
                    onClick={() => onClick(page)} 
                    key={page} 
                    className={`dot ${isActivePage ? 'dot-filled' : ''}`}
                    type='button'/> 
            }) 
        }
    </div>
}
 
const CustomCarousel = ({ children }) => {
    return (
        <Carousel
            renderArrow={customArrow}
            renderPagination={dotsPagination}>
            { children }
        </Carousel>
    )
}

export default CustomCarousel

export const NumbersCarousel = ({ children, ...props }) => {
    return (
        <Carousel
            {...props}
            renderArrow={customArrow}
            renderPagination={numbersPagination}>
            { children }
        </Carousel>
    )
}
